<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Empresas</h1>
		</div>
		<br />
		<DataGrid :config="dataGridConfig" :data="empresas" :select="seleccionar" @actions="dataGridAction">
			<div class="col-md-2">
				<button class="btn warning-btn" @click="obtener_empresas">Recargar</button>
			</div>
			<div class="col-md-2">
				<button class="btn complementary-btn" @click="agregar_empresa = 1">Crear empresa</button>
			</div>
			<div class="col-md-2">
				<button class="btn secondary-btn" @click="editar_empresa">Editar empresa</button>
			</div>
		</DataGrid>

		<Modal v-if="agregar_empresa != null" class="bpb-modal">
			<h2 class="title">{{ agregar_empresa == 1 ? 'Nueva' : 'Editar' }} empresa</h2>
			<div class="body">
				<div class="row form-group">
					<label for="nombre" class="col-form-label col-sm-4 col-lg-2">Nombre</label>
					<div class="col-sm-8 col-lg-10">
						<input v-model="empresa.empresa" type="text" name="nombre" id="nombre" class="form-control" autocomplete="off">
					</div>
				</div>
				<div class="row form-group">
					<label for="descripcion" class="col-form-label col-sm-4 col-lg-2">Descripción</label>
					<div class="col-sm-8 col-lg-10">
						<textarea v-model="empresa.descripcion" class="form-control empresa_descripcion" name="descripcion"></textarea>
					</div>
				</div>
				<div v-if="agregar_empresa==2" class="row form-group">
					<label for="titular_cuenta" class="col-form-label col-sm-4 col-lg-2">Titular de cuenta</label>
					<div class="col-sm-8 col-lg-10">
						<input v-model="empresa.titular_cuenta" class="form-control" name="titular_cuenta" />
					</div>
				</div>
				<div class="row form-group">
					<label for="banco" class="col-form-label col-sm-4 col-lg-2">Banco</label>
					<div class="col-sm-8 col-lg-10">
						<select v-model="empresa.banco" name="banco" id="banco" class="form-control">
							<option v-for="banco in catalogo_banco.valores" :key="banco.id" :value="banco.valor">{{ banco.nombre }}</option>
						</select>
					</div>
				</div>
				<div v-if="agregar_empresa==2" class="row form-group">
					<label for="clabe_interbancaria" class="col-form-label col-sm-4 col-lg-2">Clabe interbancaria</label>
					<div class="col-sm-8 col-lg-10">
						<input v-model="empresa.clabe_interbancaria" class="form-control" name="clabe_interbancaria" />
					</div>
				</div>
				<div class="row form-group">
					<label for="cuenta_contable" class="col-form-label col-sm-4 col-lg-2">Cuenta contable</label>
					<div class="col-sm-8 col-lg-10">
						<select v-model="empresa.cuenta_contable" name="cuenta_contable" id="cuenta_contable" class="form-control">
							<option value="na">Selecciona una cuenta contable</option>
							<option v-for="opcion in catalogo.valores" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }} ( {{ opcion.valor }} )</option>
						</select>
					</div>
				</div>
				<div v-if="agregar_empresa==2" class="row form-group">
					<label for="nombre_contacto" class="col-form-label col-sm-4 col-lg-2">Nombre de contacto</label>
					<div class="col-sm-8 col-lg-10">
						<input v-model="empresa.nombre_contacto" class="form-control" name="nombre_contacto" />
					</div>
				</div>
				<div v-if="agregar_empresa==2" class="row form-group">
					<label for="email_contacto" class="col-form-label col-sm-4 col-lg-2">Email de contacto</label>
					<div class="col-sm-8 col-lg-10">
						<input v-model="empresa.email_contacto" class="form-control" name="email_contacto" />
					</div>
				</div>
				<div class="row form-group">
					<label for="rfc" class="col-form-label col-sm-4 col-lg-2">RFC</label>
					<div class="col-sm-8 col-lg-10">
						<input v-model="empresa.rfc" class="form-control" name="rfc" />
					</div>
				</div>
				<div class="row form-group">
					<label for="estatus" class="col-form-label col-sm-4 col-lg-2">Estatus</label>
					<div class="col-sm-8 col-lg-10">
						<select v-model="empresa.estatus" name="estatus" id="estatus" class="form-control">
							<option value="1">Activo</option>
							<option value="0">Inactivo</option>
						</select>
					</div>
				</div>
				<br/>
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-3">
							<button class="btn principal-btn" @click="guardar_empresa">Guardar</button>
						</div>
						<div class="col-md-3">
							<button class="btn complementary-btn" @click="cancelar_empresa">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'

	import api from '@/apps/tesoreria/api/tesoreria'
	import apiCatalogo from '@/apps/tesoreria/api/catalogos'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: () => ({
			dataGridConfig: {
				name: 'empresas'
				,cols: {
					id: 'ID'
					,empresa: 'Empresa'
					,descripcion: 'Descripción'
					,estatus: 'Estatus'
				}
				,filters: {
					cols: {
						id: 'ID'
						,empresa: 'Empresa'
						,descripcion: 'Descripción'
						,rfc: 'RFC'
						,estatus: {
							text: 'Estatus'
							,options: {
								0: 'Inactivo'
								,1: 'Activo'
							}
						}
						,created_at: 'Fecha de creación'
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					estatus: function(val) {
						return val == 1 ? 'Activo' : 'Inactivo';
					}
				}
			}
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,empresa: {
				empresa: null
				,descripcion: null
				,estatus: 1
				,cuenta_contable: null
			}
			,empresas: []
			,seleccionar: false
			,seleccionados: []
			,agregar_empresa: null
			,catalogo: {
				catalogo: null
				,codigo: null
				,estatus: 1
				,valores: []
			}
			,catalogo_banco: {
				catalogo: null
				,codigo: null
				,estatus: 1
				,valores: []
			}
		})
		,created() {
			this.obtener_empresas();
			this.obtener_catalogo();
		}
		,methods: {
			obtener_empresas: function() {
				api.listar_empresas(this.options)
				.then(res => {
					this.$log.info('res', res);
					this.empresas = res.data.data;

					this.dataGridConfig.paginator.pagina_actual = res.data.current_page;
					this.dataGridConfig.paginator.total_registros = res.data.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.data.per_page);
				})
				.catch(err => {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err,'Error');
				})
			}
			,obtener_catalogo: async function() {
				try {
					this.catalogo = (await apiCatalogo.buscar_catalogo('cuentas_contables')).data;
					this.catalogo_banco = (await apiCatalogo.buscar_catalogo('bancos')).data;
				}catch(e) {
					this.$log.info('Error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,dataGridAction: function(tipo, data) {
				if (tipo == 'options') {
					this.options = data;
					this. obtener_empresas();
				}
				else {
					this.seleccionados = data;
					this.seleccionar = true;
				}
			}
			,guardar_empresa: function() {
				if (!this.empresa.cuenta_contable || this.empresa.cuenta_contable == 'na')
					return this.$helper.showMessage('Error','Debes seleccionar el tipo de cuenta contable','error','alert');

				if (!this.empresa.rfc || this.empresa.rfc == '')
					return this.$helper.showMessage('Error','Debes indicar el RFC','error','alert');

				if (!this.empresa.cuenta)
					delete this.empresa.cuenta

				if (this.agregar_empresa == 1) {
					api.crear_empresa(this.empresa)
					.then(res => {
						this.$log.info('res', res);
						this.obtener_empresas();
						this.cancelar_empresa();
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					});
				}else {
					api.editar_empresa(this.empresa.id, this.empresa)
					.then(res => {
						this.$log.info('res', res);
						this.obtener_empresas();
						this.cancelar_empresa();
						this.seleccionar = false;
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					});
				}
			}
			,cancelar_empresa: function() {
				this.empresa = {
					nombre: null
					,descripcion: null
					,estatus: 1
				}

				this.agregar_empresa = null;
				this.seleccionar = false;
			}
			,editar_empresa: function() {
				if (this.seleccionados.length > 0) {
					this.empresa = this.seleccionados[0];
					this.$log.info('empresa', this.empresa);
					this.agregar_empresa = 2;
				}else {
					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error al editar empresa'
						,text: 'Necesitas seleccionar la empresa a editar'
						,duration: 5000
					});
				}
			}
			,eliminar_empresas: async function() {
				this.$log.info('seleccionados: ', this.seleccionados);
				if (this.seleccionados.length > 0) {
					this.seleccionados.forEach(async empresa => {
						await api.eliminar_empresa(empresa.id)
						.then(res => {
							this.$log.info('res', res);
						})
						.catch(err => {
							this.$log.info('err', err);
							this.$helper.showAxiosError(err,'Error');
						})
					})

					this.seleccionar = false;
					this.obtener_empresas();
				}else {
					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error al eliminar empresa'
						,text: 'Necesitas seleccionar al menos una empresa'
						,duration: 5000
					});
				}
			}
		}
	}
</script>

<style lang="scss" scope>
	.empresa_descripcion {
		width: 100%;
		height: 150px !important;
		resize: none;
	}
</style>